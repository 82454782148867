import { styled } from "@/root/stitches.config";

const BaseText = styled("div", {
    display: "block",
    color: "$white",
    fontFamily: "$main",
    "& > span": {
        overflow: "hidden"
    },
    "@desktop": { fontSize: "$normal-desktop" },
    "@laptop": { fontSize: "$normal-laptop" },
    "@tablet": { fontSize: "$normal-tablet" },
    "@mobile": { fontSize: "$normal-mobile" },
    overflow: "hidden"
});

export default function Text({ children, ...props }) {
    return <BaseText {...props}>{children}</BaseText>;
}
