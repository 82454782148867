import { createStitches } from "@stitches/react";

export const {
    config,
    createTheme,
    css,
    getCssText,
    globalCss,
    keyframes,
    prefix,
    reset,
    styled,
    theme,
} = createStitches({
    prefix: "jfest",
    theme: {
        colors: {
            primary: "#292a23",
            secondary: "#c32a35",
            tertiary: "#5c6748",
            dark: "#101d19",
            white: "#e1e1d3",
        },
        fonts: {
            main: "batangas",
            title: "go3",
        },
        fontSizes: {
            main: "1em",
            "normal-desktop": "1.5em",
            "normal-laptop": "1.40em",
            "normal-tablet": "1.25em",
            "normal-mobile": "1em",
            "title-0-desktop": "8em",
            "title-0-laptop": "7.25em",
            "title-0-tablet": "7em",
            "title-0-mobile": "5em",
            "title-1-desktop": "7em",
            "title-1-laptop": "6.25em",
            "title-1-tablet": "6em",
            "title-1-mobile": "4em",
            "title-2-desktop": "6em",
            "title-2-laptop": "5.25em",
            "title-2-tablet": "5em",
            "title-2-mobile": "3em",
            "title-3-desktop": "5em",
            "title-3-laptop": "4.25em",
            "title-3-tablet": "4em",
            "title-3-mobile": "2em",
            "title-4-desktop": "4em",
            "title-4-laptop": "4.25em",
            "title-4-tablet": "4em",
            "title-4-mobile": "2em",
            "title-5-desktop": "3em",
            "title-5-laptop": "2.25em",
            "title-5-tablet": "2em",
            "title-5-mobile": "1em",
        },
        sizes: {
            "button-desktop-height": "55px",
            "button-desktop-width": "230px",
            "button-laptop-height": "50px",
            "button-laptop-width": "250px",
            "button-tablet-height": "47.5px",
            "button-tablet-width": "215px",
            "button-mobile-height": "40px",
            "button-mobile-width": "185px",
        },
    },
    media: {
        desktop: "(min-width: 1366px)",
        laptop: "(min-width: 1025px) and (max-width: 1365px)",
        tablet: "(min-width: 769px) and (max-width: 1024px)",
        mobile: "(min-width: 319px) and (max-width: 768px)",
    },
});
